import Cookies from 'js-cookie';
const API_KEY_COOKIE_NAME = 'weather-app-api-key';

const getApi = () => {
    return Cookies.get(API_KEY_COOKIE_NAME);
};

const setApi = (yourApi) => {
    Cookies.set(API_KEY_COOKIE_NAME, yourApi, { expires: 7 });
};

export { getApi, setApi };