import React from "react";
import { useState } from "react";
import axios from "axios";
import "./App.css";
import {getApi, setApi} from "./Cookies.js";
import data from "./locality.json";

const DEFAULT_API = "0abeea56976f03aa941679842ca4a803";

function App(){
  
  const [locality, selectLocality] = useState("");
  const [city, selectCity] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [weatherData, setWeatherData] = useState(null);
  const [error, setError] = useState("");
  const [localityId, setLocalityId] = useState("");  
  const [apiKey, setApiKeyState] = useState(getApi() || DEFAULT_API);
  const [showApiKeyInput, setShowApiKeyInput] = useState(false);

  const handleCityChange = (event) => {
    const city = event.target.value;
    selectCity(city);
    const filteredCities = data.filter(item => item.city === city)
    setFiltered(filteredCities);
  }
  
  const handleLocalityChange = (event) => {
    const locality = event.target.value;
    selectLocality(locality);
    const localityItem = data.find(item => item.localityName === locality);
    setLocalityId(localityItem.localityId);
  }
  
  const handleApiChange = () => {
    setShowApiKeyInput(true);
  }
  
  const handleApiSubmit = (event) => {
    const newApiKey = event.target.elements.apiKey.value;
    setApi(newApiKey);
    setApiKeyState(newApiKey);
    setShowApiKeyInput(false);
    console.log(getApi);
    console.log(apiKey);
  }

  const fetchWeatherData = async () => {
    const url = `https://www.weatherunion.com/gw/weather/external/v0/get_locality_weather_data?locality_id=${localityId}`;
    try {
      const response = await axios.get(url, {headers: {'x-zomato-api-key': `${apiKey}`}});
      if (response.status === 200) {
        const weatherData = response.data.locality_weather_data;
        if (!weatherData.temperature) {
          setError(`${response.status}: Data unavailable`);
          setWeatherData(null);
        } else {
          setWeatherData(weatherData);
          setError("");
        }
      }
      else{
        setError(`Error ${response.status}:${response.data.message}`)
        setWeatherData(null);
      }
    } catch (err) {
      if (error.response) {
        setError(`Error ${error.response.status}: ${error.response.statusText}`);
      } else {
        setError("Error fetching weather data");
      }
      setWeatherData(null);
    }
  }
  
  return <>
      <h1 className="title">Wetherex</h1>
      <select onChange={handleCityChange} value={city}>
        <option value="">Select a City</option> 
        {Array.from(new Set(data.map(item => item.city))).map(city => (
          <option key={city} value={city}>{city}</option>
        ))}
      </select>
      {city && 
      <select onChange={handleLocalityChange} value={locality}>
        <option value="">Select a Locality</option> 
        {Array.from(new Set(filtered.map(item => item.localityName))).map(locality => (
          <option key={locality} value={locality}>{locality}</option>
        ))
        }
      </select>
      }
      <button onClick={fetchWeatherData}>Get Weather</button>
      { weatherData && (
      <div>
          <p>Temperature: {weatherData.temperature} °C</p>
          <p>Humidity: {weatherData.humidity} %</p>
          <p>Wind Speed: {weatherData.wind_speed} m/s</p>
          <p>Wind Direction: {weatherData.wind_direction} °</p>
          <p>Rain Intensity: {weatherData.rain_intensity}</p>
          <p>Rain Accumulation: {weatherData.rain_accumulation} mm</p>
        </div>
      )}
      {error && (
        <p> {error} </p>
      )}
      <div className="api-change">
        <button onClick={handleApiChange}>Change API key</button>
        {showApiKeyInput && (
          <form onSubmit={handleApiSubmit}>
            <input type="text" placeholder="Enter key" name="apiKey"></input>
            <button type="submit">Use API key</button>
          </form>
        )}
      </div>
  </>
}

export default App;